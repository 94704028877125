import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'
import Vue from 'vue'

export default function () {
	const bugsnagApiKey = process.env.BUGSNAG_JS_API_KEY || process.env.BUGSNAG_API_KEY

	if (bugsnagApiKey) {
		Bugsnag.start({
			apiKey: bugsnagApiKey,
			plugins: [new BugsnagPluginVue()],
			user: {
				email: window.current_user?.email,
				id: window.current_user?.id,
				name: window.current_user?.name,
			},
		})

		const bugsnagVue = Bugsnag.getPlugin('vue')

		bugsnagVue.installVueErrorHandler(Vue)
	}
}
